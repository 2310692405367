import { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Code,
  Box,
  useClipboard,
} from "@chakra-ui/react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { generateCurlCommand } from "../utils";

// Inside CurlModal component
const GenerateCurlButton = ({ request }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const code = generateCurlCommand(request);
  const { hasCopied, onCopy } = useClipboard(code);

  return (
    <>
      {isOpen && (
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader size="sm">cURL Command</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box position="relative" p={4}>
                <Code
                  display="block"
                  whiteSpace="pre-wrap"
                  p={4}
                  borderRadius="md"
                  bgColor="gray.100"
                >
                  {code}
                </Code>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onCopy} size="sm">
                {hasCopied ? "Copied" : "Copy"}
              </Button>
              <Button size="sm" variant="ghost" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Button
        onClick={onOpen}
        size="sm"
        aria-label="Generate cURL"
        leftIcon={<ExternalLinkIcon />}
      >
        Generate cURL
      </Button>
    </>
  );
};

export default GenerateCurlButton;
