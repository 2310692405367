import React from "react";
import { Box, Heading, Text, Link } from "@chakra-ui/react";

const TitleSection = () => {
  return (
    <Box bg="brand.background" px={{ base: 4, md: 8 }} py={{ base: 5, md: 6 }}>
      <Box maxWidth="1280px" mx="auto">
        <Heading as="h1" size="xl" mb={4}>
          Playground for APIs in the Browser
        </Heading>
        <Text fontSize="lg" mb={2}>
          <Link href="https://www.moesif.com" isExternal>
            Moesif
          </Link>{" "}
          created apirequest.io as a free tool to help developers have an easy
          way to experiment with new APIs from the browser. One of the most
          common scenarios is allow your to string together an list of API calls
          and see the results. Our mission is to grow the API economy and make
          it easier to productize and consume APIs.
        </Text>
        <Text fontSize="lg">
          Getting CORS Errors? Download the free{" "}
          <Link
            href="https://chromewebstore.google.com/detail/moesif-origin-cors-change/digfbfaphojjndkpccljibejjbppifbc"
            isExternal
          >
            CORS Chrome extension
          </Link>{" "}
          from Moesif.
        </Text>
      </Box>
    </Box>
  );
};

export default TitleSection;
