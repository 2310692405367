import React from 'react';
import { Box, Text, Flex, Grid } from '@chakra-ui/react';
import { differenceInMilliseconds } from 'date-fns';

const TimeDurationDisplay = ({ startTime, endTime }) => {
  const start = new Date(startTime);
  const end = new Date(endTime);
  const duration = differenceInMilliseconds(end, start);

  return (
    <Box>
      <Grid templateColumns="1fr auto" gap={4} alignItems="start">
        <Box>
          <Text fontWeight="bold">Request Start & End Time</Text>
          <Text fontSize="sm">{start.toLocaleString()} -</Text>
          <Text fontSize="sm">{end.toLocaleString()}</Text>
        </Box>
        <Box textAlign="right">
          <Text fontWeight="bold">Duration</Text>
          <Text fontSize="sm">{`${duration}ms`}</Text>
        </Box>
      </Grid>
    </Box>
  );
};

export default TimeDurationDisplay;
