import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
} from "@chakra-ui/react";

const httpMethods = [
  "GET",
  "POST",
  "PUT",
  "DELETE",
  "PATCH",
  "HEAD",
  "OPTIONS",
  "CONNECT",
  "TRACE",
];

const MethodUrlInput = ({ method, setMethod, url, setUrl }) => {
  return (
    <FormControl id="method-url">
      <FormLabel>Method & URL</FormLabel>
      <InputGroup>
        <InputLeftAddon
          children={
            <Select
              size="sm"
              variant="unstyled"
              value={method}
              px={0}
              onChange={(e) => setMethod(e.target.value)}
            >
              {httpMethods.map((op) => (
                <option key={op} value={op}>
                  {op}
                </option>
              ))}
            </Select>
          }
        />
        {/* <InputLeftElement width="4.5rem">
          <Select
            size="sm" // Adjust the size prop as needed
            variant="unstyled"
            value={method}
            onChange={(e) => setMethod(e.target.value)}
            fontSize="sm" // Adjust font size as needed
            px={1} // Adjust padding as needed
          >
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
          </Select>
        </InputLeftElement> */}

        <Input
          type="url"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="https://example.com/resource"
        />
      </InputGroup>
    </FormControl>
  );
};

export default MethodUrlInput;
