import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  IconButton,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

import TimelineMarker from "./TimelineMarker";
import MethodUrlInput from "./MethodUrlInput";
import KeyValuePairsInput from "./KeyValuePairsInput";

import GenerateCurlButton from "./GenerateCurlButton";
import DebouncedPrettyJSONTextarea from "./DebouncedPrettyJSONTextarea";
import { indexToLabel } from '../common/dataUtils';

const RequestForm = ({ request, index, onUpdate, removeRequest }) => {
  const [url, setUrl] = useState(request.url);
  const [method, setMethod] = useState(request.method);
  const [queryParams, setQueryParams] = useState(request.queryParams || []);
  const [headers, setHeaders] = useState(request.headers || []);
  const [body, setBody] = useState(request.body);
  const [delay, setDelay] = useState(request.delay || 0);

  useEffect(() => {
    // Notify parent component of the request update
    onUpdate({ url, method, queryParams, headers, body, delay });
  }, [url, method, queryParams, headers, body, delay]);

  // Style configs
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <HStack align="stretch" w="full">
      <TimelineMarker hasDot />
      <VStack align="stretch" w="full">
        <HStack justifyContent="space-between">
          <h5>Request {indexToLabel(index)}</h5>
          <span>
            <GenerateCurlButton request={request} />{" "}
            <IconButton
              onClick={removeRequest}
              size="sm"
              aria-label="Generate cURL"
              icon={<DeleteIcon />}
            />{" "}
          </span>
        </HStack>
        <Box
          borderWidth="1px"
          borderTopRadius="none" // Apply border-radius to top corners only
          borderBottomRadius="lg" // Ensure bottom corners are not rounded
          p={4}
          borderColor={borderColor}
          boxShadow="0 2px 3px rgba(0, 0, 0, 0.1)" // Custom shadow value
          bg="brand.white"
        >
          <VStack spacing={4} align="stretch" w="full">
            <MethodUrlInput
              method={method}
              setMethod={setMethod}
              url={url}
              setUrl={setUrl}
            />

            <FormControl id={`query-params-${index}`}>
              <FormLabel>Query Parameters (Optional)</FormLabel>
              <KeyValuePairsInput
                pairs={queryParams}
                setPairs={setQueryParams}
              />
            </FormControl>

            <FormControl id={`headers-${index}`}>
              <FormLabel>Headers (Optional)</FormLabel>
              <KeyValuePairsInput pairs={headers} setPairs={setHeaders} />
            </FormControl>

            <FormControl id={`body-${index}`}>
              <FormLabel>Request Body (Optional)</FormLabel>
              <DebouncedPrettyJSONTextarea
                value={body}
                onChange={(value) => setBody(value)}
              />
            </FormControl>

            <FormControl id={`delay-${index}`}>
              <FormLabel>Delay (ms)</FormLabel>
              <Input
                type="number"
                value={delay}
                onChange={(e) => setDelay(e.target.value)}
                placeholder="0"
              />
            </FormControl>
          </VStack>
        </Box>
      </VStack>
    </HStack>
  );
};

export default RequestForm;
