import React from "react";
import { VStack, HStack, Input, IconButton, Button } from "@chakra-ui/react";
import { DeleteIcon, AddIcon } from "@chakra-ui/icons";

const KeyValuePairsInput = ({ pairs, setPairs }) => {
  const handleAddPair = () => {
    setPairs([...pairs, { key: "", value: "" }]);
  };

  const handleRemovePair = (index) => {
    setPairs(pairs.filter((_, idx) => idx !== index));
  };

  const handleKeyChange = (e, index) => {
    const newPairs = [...pairs];
    newPairs[index].key = e.target.value;
    setPairs(newPairs);
  };

  const handleValueChange = (e, index) => {
    const newPairs = [...pairs];
    newPairs[index].value = e.target.value;
    setPairs(newPairs);
  };

  return (
    <>
      {pairs.map((pair, index) => (
        <HStack key={index} spacing={2} align="center" mt={1}>
          <Input
            size="sm"
            value={pair.key}
            onChange={(e) => handleKeyChange(e, index)}
            placeholder="Key"
          />
          <Input
            size="sm"
            value={pair.value}
            onChange={(e) => handleValueChange(e, index)}
            placeholder="Value"
          />
          <IconButton
            size="sm"
            aria-label="Remove pair"
            icon={<DeleteIcon />}
            onClick={() => handleRemovePair(index)}
          />
        </HStack>
      ))}
      <IconButton
        mt={2}
        aria-label="Remove pair"
        icon={<AddIcon />}
        onClick={handleAddPair}
        size="sm"
        width="full"
      />
    </>
  );
};

export default KeyValuePairsInput;
