import { extendTheme, theme } from "@chakra-ui/react";

const formComponents = [
  // "Button",
  // "Input",
  // "Textarea",
  // "Select",
  // "Checkbox",
  // "Radio",
  // "Switch",
  // "FormLabel",
  // "NumberInput",
  // "PinInput",
  // "Slider",
  // "Editable",
  // "Menu",
  // "Tabs",
  // "Tag",
  // "Modal",
  // "Drawer",
  // "Accordion",
  // "AlertDialog",
  // "Popover",
  // "Tooltip",
  // "Breadcrumb",
];

const commonStyles = {
  h: "1.5rem",
  fontSize: "sm",
  px: "0.75rem", // Assuming this is the padding you want for all components
};

const typographyStyles = {
  fontSize: "sm",
  fontWeight: "normal", // Assuming you want a normal font weight
  color: "gray.600", // Assuming a default color for form labels
};

const typographyStyledComponents = [
  // "Text",
  // "Heading",
  // "FormLabel",
  // "MenuItem",
  // "Button", // For text inside buttons
  // "Input", // Placeholder and input text
  // "Textarea",
  // "Select", // For text inside the select dropdown
  // "Tag",
  // "Badge",
  // "AlertDialogHeader",
  // "AlertDialogBody",
  // "ModalHeader",
  // "ModalBody",
  // "DrawerHeader",
  // "DrawerBody",
  // "PopoverHeader",
  // "PopoverBody",
  // "AccordionButton", // Text in Accordion header
  // "BreadcrumbLink",
  // "BreadcrumbItem", // The container might also use some typography styles
  // "Tabs", // Tab labels
  // "TabList", // Container for Tab components
  // "TabPanels", // Container for TabPanel components
  // "TabPanel", // Content for each tab
  // "Tooltip", // Although often styled separately
  // "MenuButton", // Text inside menu buttons
  // "MenuList", // Container for MenuItem components
  // "MenuOptionGroup", // Text label for option groups
  // "MenuItemOption", // Text for each selectable option
  // "Checkbox", // Label text for checkboxes
  // "Radio", // Label text for radio buttons
  // "Switch", // Label text for switch controls
  // "Editable", // The text that becomes editable on interaction
  // "StatLabel", // Labels inside Stat component
  // "StatHelpText", // Help text inside Stat component
  // "StatNumber", // Numbers inside Stat component
  // "Stat", // The container might also use some typography styles
  // "ProgressLabel", // Inside Progress component, if used
  // "List", // Container for ListItems
  // "ListItem", // Text in list items
  // "NumberInput", // Input text for numbers
  // "NumberInputField", // Field of the NumberInput
  // "NumberInputStepper", // Stepper button text for NumberInput
  // "SliderThumb", // Text inside the thumb of the Slider component, if used
  // "SliderMark", // Text mark labels for Slider, if used
];

const components = {};

formComponents.forEach((comp) => {
  components[comp] = {
    baseStyle: {
      ...commonStyles,
    },
  };
});

typographyStyledComponents.forEach((comp) => {
  components[comp] = {
    baseStyle: {
      ...typographyStyles,
    },
  };
});

components.Link = {
  baseStyle: {
    color: "brand.primary",
  },
};

const customTheme = extendTheme({
  // @dan, please adjust font size or colors as needed
  // below are original fonts
  // xs: 0.75rem (12px)
  // sm: 0.875rem (14px)
  // md: 1rem (16px)
  // lg: 1.125rem (18px)
  // xl: 1.25rem (20px)
  // 2xl: 1.5rem (24px)
  // 3xl: 1.875rem (30px)
  // 4xl: 2.25rem (36px)
  // 5xl: 3rem (48px)
  // 6xl: 3.75rem (60px)
  fontSizes: {
    xs: '0.65rem',
    sm: '0.75rem',
    md: '0.9rem',
    lg: '1.0rem',
  },
  // @dan, below brand colors are copied from Moesif React repo.
  colors: {
    brand: {
      'light-text': '#ededed',
      text: '#4a4a4a',
      'moesif-black': '#272f34',
      'dark-highlight': '#38434a',
      'white-highlight': '#f1f3f4',
      secondary: '#1387ff',
      primary: '#ff4f00',
      'primary-lighter': '#ffc0a4',
      'primary-lightest': '#ffede6',
      gray: '#b5b5b5', // should give this a better name
      'gray-2': '#666',
      muted: '#bbb',
      'pricing-light-gray': '#d2dade',
      'illustration-med-gray': '#aab4bd',
      'illustration-dark-gray': '#5b676e',
      white: '#fff',
      black: '#000',
      selected: '#2684ff',
      success: '#54c000',
      danger: '#e51c23',
      'danger-secondary': '#d9534f',
      warning: '#ff9800',
      'warning-secondary': '#ffffff',
      error: '#f3001D66',
      transparent: '#ffffff00',
      background: 'var(--gray-50, #F7FAFC)'
    },
  },
  components,
});

export default customTheme;
