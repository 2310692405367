import { Flex, Text, Link, Box } from "@chakra-ui/react";
import { ReactComponent as ApiRequestLogo } from "../images/apirequestio-logo-icon.svg";
import { ReactComponent as MoesifLogoColor } from "../images/moesifLogoColor.svg";
function TopHeader() {
  return (
    <Box bg="gray.100" px={{ base: 4, md: 8 }}>
      <Flex
        py={4}
        justify="space-between"
        alignItems="center"
        wrap="wrap"
        maxWidth="1280px"
        mx="auto"
      >
        <Flex align="center">
          <ApiRequestLogo />
          <Text
            color="#2E636B" // The specified color
            fontFamily="'Roboto Mono', monospace" // The font family, with a fallback to monospace
            fontSize="24px" // The font size
            fontStyle="normal" // The font style
            fontWeight="700" // The font weight
            lineHeight="normal" // The line height
            letterSpacing="-1px" // The letter spacing
          >
            apirequest.io
          </Text>
        </Flex>
          <Flex align="center">
          <span>crafted by</span>
          <Link href="https://www.moesif.com" isExternal>
            <MoesifLogoColor />
          </Link>
        </Flex>
      </Flex>
    </Box>
  );
}

export default TopHeader;
