import React, { useState } from "react";
import {
  Box,
  VStack,
  Grid,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import RequestList from "./RequestList"; // Make sure to import RequestList
import History from "./History"; // Make sure to import History
import { sendEventToMoesifIfPossible } from "./moesifApi";
import Banner from "./Banner";

const Workspace = () => {
  const [history, setHistory] = useState([]); // This would be your history state
  const toast = useToast();

  const addHistoryEvent = (event, originalIndex) => {
    setHistory((prev) => [{ ...event, originalIndex }, ...prev]);

    sendEventToMoesifIfPossible(event).catch((err) => {
      toast({
        title: "Error sending data to Moesif.",
        description: `${err.toString()}. Please check your configuration`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    });
  };

  return (
    <Box minH="100vh" py={6} bg="brand.background" px={{ base: 4, md: 8 }}>
      <VStack spacing={10} align="stretch" maxWidth="1280px" mx="auto">
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <GridItem colSpan={2}>
            <Banner />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }} maxW="600px" minW="300px">
            <RequestList addHistoryEvent={addHistoryEvent} />
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <History history={history} maxW="600px" minW="300px"/>
          </GridItem>
        </Grid>
      </VStack>
    </Box>
  );
};

export default Workspace;
