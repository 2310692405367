import { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  VStack,
  Tooltip,
  Box,
  Link,
  Divider,
} from "@chakra-ui/react";
import {
  clearMoesifConfig,
  getMoesifConfigFromLocalStorage,
  saveMoesifConfigToLocalStorage,
} from "./moesifApi";
import { ExternalLinkIcon } from "@chakra-ui/icons";

// Inside CurlModal component
const MoesifConfigManager = ({}) => {
  const [applicationId, setApplicationId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    const config = getMoesifConfigFromLocalStorage();
    setApplicationId(config.applicationId);
    setUserId(config.userId);
    setCompanyId(config.companyId);
  }, []);

  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader size="sm">
            Capture the API Events to Moesif Account for Analytics
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack w="full">
              <Box>
                You can send each new{" "}
                <Link
                  href="https://www.moesif.com/docs/getting-started/api-events/"
                  isExternal
                >
                  API call
                </Link>{" "}
                to a{" "}
                <Link href="https://www.moesif.com" isExternal>
                  Moesif Account
                </Link>{" "}
                (under 30,000 events/month is completely Free), from there you
                can leverage many of the Moesif features such as{" "}
                <Link
                  href="https://www.moesif.com/docs/api-dashboards/creating-a-dashboard/#types-of-workspaces"
                  isExternal
                >
                  Shareable Workspaces
                </Link>{" "}
                for collaboration with your co-workers.
              </Box>
              <Divider />
              <FormControl id={`appId`}>
                <FormLabel>Moesif Application Id (Required)</FormLabel>
                <Input
                  value={applicationId}
                  onChange={(e) => setApplicationId(e.target.value)}
                  placeholder="eyXXXXXXXXXXX"
                />
                <FormHelperText>
                  You can obtain your Moesif Application Id from your Moesif
                  Account.
                </FormHelperText>
              </FormControl>
              <FormControl id={`userId`}>
                <FormLabel>
                  <Link
                    href="https://www.moesif.com/docs/getting-started/users/"
                    isExternal
                  >
                    User Id
                  </Link>{" "}
                  (Optional)
                </FormLabel>
                <Input
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  placeholder="Associate events with Users Objects"
                />
              </FormControl>
              <FormControl id={`companyId`}>
                <FormLabel>
                  <Link
                    href="https://www.moesif.com/docs/getting-started/companies/"
                    isExternal
                  >
                    Company Id
                  </Link>{" "}
                  (Optional)
                </FormLabel>
                <Input
                  value={companyId}
                  onChange={(e) => setCompanyId(e.target.value)}
                  placeholder="Associate events with Company Objects"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Tooltip
              label="The configuration in this form is saved to your browser's localStorage, you can clear it anytime. Moesif Application Id is required."
              placement="auto"
              hasArrow
            >
              <Button
                isDisabled={!applicationId}
                size="sm"
                colorScheme="blue"
                mr={2}
                onClick={() => {
                  saveMoesifConfigToLocalStorage({
                    applicationId,
                    userId,
                    companyId,
                  });
                  onClose();

                  if (window.moesifCollector) {
                    window.moesifCollector.track(
                      "apirequest_saved_application_id"
                    );
                  }
                }}
              >
                Save
              </Button>
            </Tooltip>
            <Button
              size="sm"
              colorScheme="blue"
              variant="outline"
              mr={2}
              onClick={() => {
                clearMoesifConfig();
                setApplicationId(null);
                setCompanyId(null);
                setUserId(null);
                onClose();
              }}
            >
              Clear
            </Button>
            <Button size="sm" variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tooltip
        label="Persist the history of events, and share with co-workers"
        placement="auto"
        hasArrow
      >
        <Button
          onClick={() => {
            onOpen();

            if (window.moesifCollector) {
              window.moesifCollector.track("apirequest_opened_save_history");
            }
          }}
          aria-label="Save History"
          leftIcon={<ExternalLinkIcon />}
        >
          {!isOpen && applicationId ? "Sending to Moesif" : "Save History"}
        </Button>
      </Tooltip>
    </>
  );
};

export default MoesifConfigManager;
