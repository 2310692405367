import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const EventSubSection = ({ title, children }) => {
  return (
    <Box>
      <Text fontWeight="bold" mb={2}>{title}</Text>
      {children}
    </Box>
  );
};
export default EventSubSection;
