import { Outlet } from "react-router-dom";
import Footer from '../components/Footer';
import TopHeader from '../components/TopHeader';

export default function Root() {
  return (
    <>
    <TopHeader />
      <div id="detail">
        <Outlet />
      </div>
      <Footer />
    </>
  );
}
