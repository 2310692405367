// src/utils.js

export const parseHeaders = (headersArray) =>
  headersArray.reduce((acc, header) => {
    if (header.key && header.value) {
      acc[header.key] = header.value;
    }
    return acc;
  }, {});

export const formatQueryParams = (keyValuePairArray) => {
  const params = new URLSearchParams();

  keyValuePairArray.forEach((pair) => {
    if (pair.key) params.append(pair.key, pair.value);
  });

  const queryString = params.toString(); // This is your encoded query string

  return queryString;
};

export const generateCurlCommand = (request) => {
  const url = request.url;

  const queryString =
    request.queryParams?.length >= 1
      ? formatQueryParams(request.queryParams)
      : "";

  const fullUrl = queryString ? `${url}?${queryString}` : url;

  let curlCommand = `curl -X ${request.method.toUpperCase()} "${fullUrl}"`;

  request.headers.forEach((header) => {
    if (header.key && header.value) {
      curlCommand += ` -H "${header.key}: ${header.value}"`;
    }
  });

  if (request.body) {
    curlCommand += ` --data-raw '${request.body}'`;
  }

  return curlCommand;
};

function parseCurlCommand(curlCommand) {
  let method = "GET"; // Default to GET unless specified
  let url = "";
  let headers = {};
  let body = "";

  // Normalize command and split arguments
  const args = curlCommand.match(/(?:[^\s"']+|["'][^"']*["'])+/g);

  // Iterate over each argument to parse the command
  let i = 0;
  while (i < args.length) {
    const arg = args[i];
    switch (arg) {
      case "curl":
        // Increment to skip 'curl'
        i++;
        break;
      case "-X":
      case "--request":
        // Update the method and increment twice to skip the method value
        method = args[++i];
        i++;
        break;
      case "-H":
      case "--header":
        // Process headers
        const headerSplit = args[++i].split(":");
        headers[headerSplit[0].trim().replace(/['"]/g, "")] = headerSplit[1]
          .trim()
          .replace(/['"]/g, "");
        i++;
        break;
      case "-d":
      case "--data":
      case "--data-raw":
      case "--data-binary":
        // Process data and increment to skip body parsing
        body = args[++i].replace(/^['"]|['"]$/g, "");
        while (i + 1 < args.length && !args[i + 1].startsWith("-")) {
          body += " " + args[++i].replace(/^['"]|['"]$/g, "");
        }
        i++;
        break;
      default:
        // Assume it's a URL if it doesn't start with a dash and not yet assigned
        if (!arg.startsWith("-") && url === "") {
          url = arg.replace(/^['"]|['"]$/g, "");
        }
        i++;
        break;
    }
  }

  return {
    method,
    url,
    headers,
    body,
  };
}

export const parseCurl = (curlCommand) => {
  const result = parseCurlCommand(curlCommand);

  result.headers = Object.entries(result.headers).map((entry) => ({
    key: entry[0],
    value: entry[1],
  }));

  return result;
};

export async function getIpAddress() {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.ip;
  } catch (error) {
    return null;
  }
}
