import axios from "axios";

const LOCAL_STORAGE_KEY = "moesifConfig";
const MOESIF_COLLECTOR_URL = "https://api.moesif.net"

export function saveMoesifConfigToLocalStorage({
  applicationId,
  userId,
  companyId,
}) {
  try {
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        applicationId,
        userId,
        companyId,
      })
    );
  } catch (err) {
    console.log("localStorage not supported");
  }
}

export function clearMoesifConfig() {
  try {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  } catch (err) {
    console.log("localStorage not supported");
  }
}

export function getMoesifConfigFromLocalStorage() {
  try {
    const str = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (str) {
      return JSON.parse(str);
    }
  } catch (err) {
    console.log("localStorage not supported");
  }
  return {};
}

export async function sendEventToMoesifIfPossible(event) {
  const moesifConfig = getMoesifConfigFromLocalStorage();
  if (moesifConfig.applicationId) {
    if (moesifConfig.userId) {
      event.user_id = moesifConfig.userId;
    }
    if (moesifConfig.companyId) {
      event.companyId = moesifConfig.userId;
    }

    return axios({
      method: 'POST',
      url: `${MOESIF_COLLECTOR_URL}/v1/events`,
      headers: {
        'Content-Type': 'application/json',
        'X-Moesif-Application-Id': moesifConfig.applicationId
      },
      data: event
    });
  }

  return;
}
