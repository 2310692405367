import React from "react";
import {
  Box,
  VStack,
  HStack,
  Heading,
  useColorModeValue,
  Divider,
  Center,
  Text,
} from "@chakra-ui/react";
import HistoryEvent from "./HistoryEvent"; // Import your HistoryEvent component
import MoesifConfigManager from "./MoesifConfigManager";

const History = ({ history }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <VStack spacing={4} align="stretch" w="full">
      <HStack justifyContent="space-between">
        <Heading size="md">History</Heading>
        <MoesifConfigManager />
      </HStack>
      <VStack spacing={4} align="stretch" w="full">
        {history.map((event, index) => (
          <HistoryEvent key={event?.request?.time || index} event={event} />
        ))}
        {!history ||
          (history.length <= 0 && (
            <Center h="200px" /* Adjust height as needed */>
              <Box
                p={4}
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius="md"
                w="full" /* Set the width you need */
                h="full" /* Set the height you need */
                bg="gray.300" /* Light grey background */
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Text color="gray.500">No response received</Text>
              </Box>
            </Center>
          ))}
      </VStack>
    </VStack>
  );
};

export default History;
