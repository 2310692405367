export function indexToLabel(index) {
  // A constant to determine the alphabet size
  const alphabetSize = 26;

  // Determine the letter corresponding to the current index
  let letter = String.fromCharCode(
    "a".charCodeAt(0) + (index % alphabetSize)
  ).toUpperCase();

  // Calculate the number of times the letter should repeat
  let repeatCount = Math.floor(index / alphabetSize) + 1;

  // Return the letter repeated the necessary number of times
  return letter.repeat(repeatCount);
}
