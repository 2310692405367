import React, { useState } from 'react';
import { Textarea } from '@chakra-ui/react';

let timeOut = null;

const DebouncedPrettyJSONTextarea = ({ value, onChange }) => {
  const [text, setText] = useState(value);

  const tryFormatJson = (input) => {
    try {
      const json = JSON.parse(input);
      const prettyJsonString = JSON.stringify(json, null, 2);
      setText(prettyJsonString);
      if (onChange) {
        onChange(prettyJsonString);
      }
    } catch (error) {
      // If JSON is invalid, just set the text without formatting
      onChange(input);
    }
  };

  // Debounce the tryFormatJson function
  const debouncedFormatJson = (val) => {
    setText(val);

    if (timeOut) {
      clearTimeout(timeOut);
    }
    timeOut = setTimeout(() => {
      tryFormatJson(val);
    }, 200);
  }

  // Handle changes in the textarea
  const handleChange = (event) => {
    const { value } = event.target;
    setText(value);
    debouncedFormatJson(value);
  };

  return (
    <Textarea
      value={text}
      fontFamily={"monospace"}
      onChange={handleChange}
      placeholder="Enter JSON"
      size="sm"
      h="200px" // Customize the height as needed
    />
  );
};

export default DebouncedPrettyJSONTextarea;
