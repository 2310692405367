import React from "react";

import { Helmet } from "react-helmet";

import Workspace from "../components/Workspace";
import TitleSection from "../components/TitleSection";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Playground for APIs in the Browser</title>
        <meta name="description" content="A quick way to test API requests" />
      </Helmet>
      <TitleSection />
      <Workspace />
    </>
  );
}
