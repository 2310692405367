import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import moesifBrowser from "moesif-browser-js";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "./customTheme";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";
import Root from "./pages/Root";
import ErrorPage from "./pages/ErrorPage";
import Home from "./pages/Home";

if (process.env.NODE_ENV === 'production') {
  try {
    window.moesifCollector = moesifBrowser.init({
      applicationId: process.env.REACT_APP_MOESIF_COLLECTOR_PUBLISHABLE_ID,
      batchEnabled: true,
      persistenceKeyPrefix: "collector",
    });
    window.moesifCollector.track("apirequest_viewed");
  } catch (err) {}
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/:workspaceId",
        element: <Home />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={customTheme}>
      <RouterProvider router={router} fallbackElement={<div>loading...</div>} />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
