import React from "react";
import { Box, Text } from "@chakra-ui/react";
import EventSubSection from "./EventSubSection";

const KeyValueDisplay = ({ title, data, isJson = false }) => {
  // Function to format headers from key-value object to string
  const formatHeaders = (headers) => {
    return Object.entries(headers)
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n");
  };

  // Function to prettify JSON string if it's valid JSON
  const prettyPrintJson = (jsonString) => {
    try {
      if (typeof jsonString !== "string" && typeof jsonString === "object") {
        return JSON.stringify(jsonString, null, 2);
      }
      const json = JSON.parse(jsonString);
      return JSON.stringify(json, null, 2);
    } catch {
      return jsonString; // If it's not valid JSON, return the original string
    }
  };

  // Determine how to display the data based on the input type
  const displayData = isJson ? prettyPrintJson(data) : formatHeaders(data);

  return (
    <EventSubSection title={title}>
      <Box bg="gray.50" p={2} borderRadius="md">
        <Text
          fontFamily="monospace"
          fontSize="sm"
          whiteSpace="pre-wrap"
          overflow="auto"
          maxW="600px"
        >
          {displayData || "-"}
        </Text>
      </Box>
    </EventSubSection>
  );
};

export default KeyValueDisplay;
