import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
} from "@chakra-ui/react";

// Inside CurlModal component
const CurlModal = ({ isOpen, onClose, onImportCurl }) => {
  const [curlCommand, setCurlCommand] = useState("");

  const handleImport = () => {
    onImportCurl(curlCommand); // Passes the cURL command back to Workspace for processing
    setCurlCommand('');
    onClose(); // Close the modal
  };

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader size="sm">Import cURL</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Textarea
            value={curlCommand}
            onChange={(e) => setCurlCommand(e.target.value)}
            placeholder="Paste your cURL command here"
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" colorScheme="blue" mr={2} onClick={handleImport}>
            Import
          </Button>
          <Button size="sm" variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CurlModal;
