import { Flex, Text, Link, Box } from "@chakra-ui/react";

function Footer() {
  return (
    <footer>
      <Box bg="gray.100"  px={{ base: 4, md: 8 }}>
        <Flex maxWidth="1280px" justify="space-between" alignItems="center" wrap="wrap" py={8} mx="auto">
          <Flex gap={4}>
            <Link href="https://www.moesif.com/terms" isExternal>Terms of Use</Link>
            <Link href="https://www.moesif.com/privacy" isExternal>Privacy Policy</Link>
          </Flex>
          <Text fontSize="sm" color="gray.500">
            © {new Date().getFullYear()} Moesif, Inc.
          </Text>
        </Flex>
      </Box>
    </footer>
  );
}

export default Footer;
