import React from "react";
import {
  Flex,
  Text,
  Button,
  Image,
  VStack,
  Box,
  HStack,
} from "@chakra-ui/react";
import { ReactComponent as MoesifLogoSvg } from "../images/Logo.svg";
import farLeftScreenShot from "../images/Fixed-aspect-ratio-spacer.png";

const Banner = () => {
  return (
    <Box maxWidth="1280px" mx="auto">
      <Flex
        bgGradient="linear(to-r, brand.primary, orange.300)"
        color="white"
        p={{ base: 3, md: 5 }}
        direction={{ base: "column", md: "row" }}
        align="center"
        justify="center"
        width="full"
        borderRadius="lg"
        overflow="hidden" // Prevent overflow
      >
        {/* First Column: Screenshot */}
        <Box
          width={{ base: "full", md: "auto" }}
          flexShrink={0}
          order={{ base: 0, md: 1 }} // Control the order on mobile
        >
          <Image
            src={farLeftScreenShot}
            alt="moesif api monitoring"
            objectFit="cover"
          />
        </Box>

        {/* Second Column: Logo and Text */}
        <VStack
          spacing={2}
          align="flex-start"
          flex={1}
          order={{ base: 2, md: 2 }} // Middle column remains in the middle
          p={{ base: 3, md: 4 }}
          wordBreak="break-word" // Ensure text wraps
        >
          <Box height={30}>
            <MoesifLogoSvg width="auto" height="full" />
          </Box>
          <Text fontSize={{ base: "lg", md: "2xl" }} fontWeight="bold">
            Monitor and Analyze API Traffic
          </Text>
          <Text>
            Use Moesif to up your API game with powerful analytics and more.
          </Text>
        </VStack>

        {/* Third Column: Buttons and Trial Text */}
        <VStack
          spacing={4}
          align="flex-start"
          width={{ base: "full", md: "auto" }}
          flexShrink={0}
          order={{ base: 1, md: 3 }} // Button column to the end on mobile
          p={{ base: 3, md: 4 }}
        >
          <HStack>
            <Button
              variant="outline"
              as="a"
              size="lg"
              fontWeight="bold"
              color="white"
              _hover={{ bg: "orange.500", borderColor: "orange.500" }}
              href="https://www.moesif.com/features/api-monitoring"
              target="_blank"
              boxShadow="md"
              onClick={() => {
                if (window.moesifCollector) {
                  window.moesifCollector.track("apirequest_clicked_save_cta", {
                    cta: "Learn More",
                  });
                }
              }}
            >
              Learn More
            </Button>
            <Button
              colorScheme="yellow"
              as="a"
              bg="white"
              size="lg"
              fontWeight="bold"
              color="brand.primary"
              _hover={{ bg: "yellow.500" }}
              boxShadow="md"
              href="https://www.moesif.com/wrap?onboard=true"
              target="_blank"
              onClick={() => {
                if (window.moesifCollector) {
                  window.moesifCollector.track("apirequest_clicked_save_cta", {
                    cta: "Try for Free",
                  });
                }
              }}
            >
              Try for Free
            </Button>
          </HStack>
          <Text fontSize="md" textAlign="center" alignContent="center">
            14-day free trial. No credit card required.
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
};

export default Banner;
