import React from "react";
import { Box } from "@chakra-ui/react";

const TimelineMarker = ({ hasDot, skipLine }) => {
  // Create a vertical striped background image
  const stripedBg = `repeating-linear-gradient(
    to bottom,
    #718096,
    #718096 2px,
    transparent 2px,
    transparent 10px
  )`;

  return (
    <Box position="relative" width="10px" flexShrink={0}>
      {/* Dot */}
      {hasDot && (
        <Box
          position="absolute"
          left="50%"
          top="11px"
          transform="translateX(-50%)"
          height="10px"
          width="10px"
          borderRadius="full"
          bg="#718096"
          border="0px solid white"
          zIndex={1}
        />
      )}
      {/* Striped line */}
      {!skipLine && (
        <Box
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          width="2px"
          top={hasDot ? "12px" : "0"} // Adjust starting position if dot is present
          bottom="-2"
          bgImage={stripedBg}
          zIndex={0}
        />
      )}
    </Box>
  );
};

export default TimelineMarker;
