import React, { useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Collapse,
  VStack,
  Text,
  Flex,
  Spacer,
  HStack,
  useColorModeValue,
  Divider,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import TimelineMarker from "./TimelineMarker";
import InformationBox from "./InformationBox";
import TimeDurationDisplay from "./TimeDurationDisplay";
import StatusBadge from "./StatusBadge";
import { indexToLabel } from "../common/dataUtils";

function formatLocalTime(isoTimestamp) {
  const date = new Date(isoTimestamp);
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}

const HistoryEvent = ({ event, index }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(true);

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <HStack align="stretch">
      <TimelineMarker hasDot />
      <VStack align="stretch" w="full">
        <Box className="history-event">
          <Tabs size="sm">
            <Flex justifyContent="space-between" alignItems="center" p={0}>
              <h5>{`Response ${
                typeof event.originalIndex === "number"
                  ? indexToLabel(event.originalIndex)
                  : ""
              }`}</h5>
              <Text ml={1} textColor="brand.muted">
                {formatLocalTime(event.request.time)}{" "}
                {event?.response?.status && (
                  <StatusBadge statusCode={event.response.status} skipMessage />
                )}
              </Text>
              <Spacer />
              <TabList>
                <Tab>Request</Tab>
                <Tab>Response</Tab>
              </TabList>
            </Flex>
            <Box
              borderWidth="1px"
              borderTopRadius="none" // Apply border-radius to top corners only
              borderBottomRadius="lg" // Ensure bottom corners are not rounded
              p={2}
              position="relative"
              borderColor={borderColor}
              boxShadow="0 2px 3px rgba(0, 0, 0, 0.1)" // Custom shadow value
              bg="brand.white"
            >
              <Button
                onClick={togglePanel}
                size="xs"
                variant="ghost"
                position="absolute"
                top={1} // Adjust the value as needed
                right={1} // Adjust the value as needed
              >
                {isPanelOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
              <Collapse in={isPanelOpen}>
                <TabPanels>
                  <TabPanel>
                    <TimeDurationDisplay
                      startTime={event.request?.time}
                      endTime={event.response?.time}
                    />
                    <Divider py={2} />
                    <InformationBox {...event.request} />
                  </TabPanel>
                  <TabPanel>
                    <InformationBox {...event.response} />
                  </TabPanel>
                </TabPanels>
              </Collapse>
            </Box>
          </Tabs>
        </Box>
      </VStack>
    </HStack>
  );
};

export default HistoryEvent;
