import React from "react";
import { VStack } from "@chakra-ui/react";
import KeyValueDisplay from "./KeyValueDisplay"; // Make sure to import the component
import EventSubSection from "./EventSubSection";
import StatusBadge from './StatusBadge';

const InformationBox = ({ uri, verb, status, headers, body }) => {
  // Assuming status is a simple string, headers is an object, and body is a JSON string
  return (
    <VStack spacing={2} align="stretch">
      {verb && uri && (
        <EventSubSection title="Method & Uri">{`${verb} ${uri}`}</EventSubSection>
      )}
      {status && <EventSubSection title="Status"><StatusBadge statusCode={status}/></EventSubSection>}
      <KeyValueDisplay title="Headers" data={headers} />
      <KeyValueDisplay title="Body" data={body} isJson={true} />
    </VStack>
  );
};

export default InformationBox;
