import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  VStack,
  HStack,
  Heading,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import RequestForm from "./RequestForm"; // Your RequestForm component
import TimelineMarker from "./TimelineMarker";

import {
  parseHeaders,
  parseCurl,
  formatQueryParams,
  getIpAddress,
} from "../utils";
import CurlModal from "./CurlModal";

const RequestList = ({ addHistoryEvent }) => {
  const toast = useToast();
  const [requests, setRequests] = useState([
    {
      url: "",
      method: "GET",
      queryParams: [{ key: "", value: "" }],
      headers: [{ key: "", value: "" }],
      body: "",
      delay: 0,
    },
  ]);

  const [myIp, setMyIp] = useState(null);

  useEffect(() => {
    getIpAddress()
      .then((ip) => {
        setMyIp(ip);
      })
      .catch((err) => {
        // do nothing,
        // just use local ip.
      });
  }, []);

  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure(); // Controls for modal

  const onFireRequest = async (request, delay = 0, originalIndex) => {
    await new Promise((resolve) => setTimeout(resolve, delay)); // Delay execution

    const { url, method, queryParams, headers, body } = request;

    if (!url && method) {
      return;
    }
    const startTime = new Date().toISOString(); // Use ISO string for the time
    const headerFormattedForAxios = parseHeaders(headers);

    const queryString = queryParams ? formatQueryParams(queryParams) : "";
    const fullUrl = queryString ? `${url}?${queryString}` : url;

    try {
      const response = await axios({
        method,
        url: fullUrl,
        headers: headerFormattedForAxios,
        data: body,
      });
      const endTime = new Date().toISOString();

      // Transform successful response data into the required format
      addHistoryEvent(
        {
          request: {
            time: startTime,
            uri: fullUrl,
            verb: method,
            // api_version: '1.1.0', // Assuming this is static or retrieved from somewhere
            ip_address: myIp || "127.0.0.1", // This should be retrieved from the request metadata if possible
            headers: headerFormattedForAxios,
            body: body,
            // transfer_encoding: '', // If you have the data, add it here
          },
          response: {
            time: endTime,
            status: response.status,
            headers: response.headers,
            body: response.data,
            // transfer_encoding: '', // If you have the data, add it here
          },
        },
        originalIndex
      );
    } catch (error) {
      const endTime = new Date().toISOString();
      // Handle error response and transform into the required format
      addHistoryEvent({
        request: {
          time: startTime,
          uri: fullUrl,
          verb: method,
          // api_version: '1.1.0', // Static or retrieved from somewhere
          ip_address: myIp || "127.0.0.1", // Should be retrieved from request metadata if possible
          headers: headerFormattedForAxios,
          body: body,
          // transfer_encoding: '', // Add if available
        },
        response: {
          time: endTime,
          status: error.response?.status || 500, // Use the status code from the error response if available
          headers: error.response?.headers || {},
          body: error.response?.data || {
            Error: error.name,
            Message: error.message,
          },
          // transfer_encoding: '', // Add if available
        },
      });
    }
  };

  const addRequest = () => {
    setRequests([
      ...requests,
      {
        url: "",
        method: "GET",
        queryParams: [{ key: "", value: "" }],
        headers: [{ key: "", value: "" }],
        body: "",
        delay: 1000,
      },
    ]);
  };

  const removeRequest = (index) => {
    const updatedRequests = [...requests];
    updatedRequests.splice(index, 1);
    setRequests(updatedRequests);
  };

  const updateRequest = (index, updatedRequest) => {
    const updatedRequests = [...requests];
    updatedRequests[index] = updatedRequest;
    setRequests(updatedRequests);
  };

  const onFireAllRequests = () => {
    requests.reduce((promiseChain, currentRequest, index) => {
      return promiseChain.then(() =>
        onFireRequest(
          currentRequest,
          index > 0 ? currentRequest.delay : 0,
          index
        )
      );
    }, Promise.resolve());
  };

  const addRequestFormFromCurl = (curlCommand) => {
    try {
      const newRequest = parseCurl(curlCommand); // Implement this function based on your parsing logic
      setRequests([...requests, newRequest]);
    } catch (err) {
      toast({
        title: "Failed to Parse cURL.",
        description: `The cURL command could be badly formatted.`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <HStack justifyContent="space-between">
        <Heading size="md">Requests</Heading>
        <Button
          isDisabled={!requests || requests.length <= 0}
          colorScheme="blue"
          onClick={onFireAllRequests}
        >
          Send
        </Button>
      </HStack>
      {requests.map((request, index) => (
        <RequestForm
          key={index}
          request={request}
          index={index}
          removeRequest={removeRequest}
          onUpdate={(updatedRequest) => updateRequest(index, updatedRequest)}
        />
      ))}
      <HStack align="stretch">
        <TimelineMarker hasDot />
        <Button leftIcon={<AddIcon />} onClick={addRequest}>
          Add Request
        </Button>
        <Button leftIcon={<AddIcon />} onClick={onModalOpen}>
          Add From Curl
        </Button>
        {isModalOpen && (
          <CurlModal
            isOpen={isModalOpen}
            onClose={onModalClose}
            onImportCurl={addRequestFormFromCurl}
          />
        )}
      </HStack>
    </VStack>
  );
};

export default RequestList;
